@tailwind base;
@tailwind components;
@tailwind utilities;

.my-masonry-grid {
    display: flex;
    margin-left: -30px;
    width: auto;
    max-width: 75%;
    margin: 0 auto;
}

.my-masonry-grid_column {
    padding: 0.25rem 0.5rem;
    background-clip: padding-box;
}

.my-masonry-grid_column > a > img { /* change div to reference your elements you put in <Masonry> */
    margin-bottom: 30px;
    width: 100%;
}

.blur {
    @apply blur-2xl;
}

.justthetips-container {
    @apply relative;
}

.justthetips-box {
    @apply absolute;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    top: calc(100% + 5px);
    display: none;
}

.justthetips-box.is-visible {
    display: block;
}

.justthetips-arrow {
    position: absolute;
    top: -10px;
    left: 50%;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent rgba(0, 0, 0, 0.7) transparent;
}